<template>
  <Aside
    :toggle-by="toggleBy"
    @oncancel="OnCancel"
    cancel-text="Cancel"
    width="1024px"
  >
    <VendorUpdate
      tabbed
      v-if="toggleBy"
      :title="title"
      :uuid="uuid"
      @onsave="OnSave"
      @oncancel="OnCancel"
      cancel-text="Cancel"
    />
  </Aside>
</template>
<script>
import { Util } from "@/helpers/utilities";
import VendorUpdate from "@/components/ui/vendor/VendorUpdate.vue";
import Aside from "@/components/ui/Aside.vue";
export default {
  components: {
    VendorUpdate,
    Aside,
  },
  props: {
    toggleBy: {
      type: Boolean,
      default: false,
    },
    uuid: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Add Vendor",
    },
  },
  emits: ["onsave", "oncancel"],
  setup(_, { emit }) {
    const OnSave = () => {
      emit("onsave");
    };
    const OnCancel = () => {
      emit("oncancel");
    };
    return {
      Util,
      OnSave,
      OnCancel,
    };
  },
};
</script>