<template>
  <BasePageContent>
    <BasePageHeader
      class="px-3"
      title="Vendors"
      description="Manage your vendors"
    >
      <ul class="nk-block-tools g-1 pr-3">
        <li class="nk-block-tools-opt">
          <a
            href="#"
            title="Add new vendor"
            class="btn btn-white btn-dim btn-outline-light"
            @click.prevent="Toggle = true"
          >
            <em class="dd-indc icon ni ni-plus"></em>
            <span class="d-inline">New vendor</span>
            <em class="dd-indc w3-text-theme icon ni ni-chevron-right"></em>
          </a>
          <AddVendor
            :toggle-by="Toggle"
            @oncancel="SaveOnNew"
            @onsave="SaveOnNew"
            title="Add new vendor"
          ></AddVendor>
        </li>
      </ul>
    </BasePageHeader>
    <div class="card-inner">
      <div class="px-1"><VendorList id="vendorList" :vendors="Vendors" /></div>
    </div>
  </BasePageContent>
</template>
<script>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import VendorList from "@/components/ui/vendor/VendorList.vue";
import AddVendor from "@/components/ui/vendor/AddVendor.vue";
import { Util } from "@/helpers/utilities";

import { useStore } from "vuex";
export default {
  components: {
    VendorList,
    AddVendor,
  },
  props: {
    addNew: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const title = ref("Vendors");
    const Vendors = computed(() => store.getters["vendors/vendors"]);
    const Toggle = ref(props.addNew);
    const router = useRouter();
    const SaveOnNew = () => {
      if (props.addNew) {
        router.push({
          name: "vendors",
        });
      } else {
        Toggle.value = false;
      }
    };
    return {
      title,
      Util,
      Vendors,
      Toggle,
      SaveOnNew,
    };
  },
};
</script>